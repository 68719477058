import React from "react";
import SendEmail from "../sendEmailVerification/components/sendEmail";
import Verification from "../sendEmailVerification/components/verification";
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "src/redux/Login/Actions";

const Confirmation = (props) => {
    const LoginState = useSelector((state) => state.Login);
    const {confirmStep ,sendSuccessfulEmailAlertModal} = LoginState;
    const dispatch = useDispatch();


    const onCloseSendEmailModalHandler =(show) => {
      dispatch(LoginActions.forgetPasswordModal({ show: false }))
    }

    const onCloseVerificationModalHandler =(show) => {
      dispatch(LoginActions.showSuccessfulEmailAlertModal({ show: false }))
    }
    // const sendEmailHandler = (values) => {
    //   dispatch(LoginActions.sendPasswordToEmail(values));
    // }

    const ConfirmationStepTarget = (step) => {
        switch (step) {
          case 0:
            return <SendEmail open={props.open} onClose={onCloseSendEmailModalHandler} onConfirm={props.onConfirm}  btnText="تایید"/>;
          case 1:
            return <Verification open={sendSuccessfulEmailAlertModal} text="در صورت صحت ایمیل وارد شده، لینک بازیابی رمز عبور ارسال شد!" onClose={onCloseVerificationModalHandler}/>;
          default:
            return <SendEmail  open={props.open}/>;
            break;
        }
      };
    return(
        <>
          {ConfirmationStepTarget(confirmStep)}
        </>
    )
}
export default Confirmation