import styled, { css } from "styled-components";
import Row from "src/kit/Row";
import Column from "src/kit/Column";


export const RegisterFormContainer = styled(Row)`
    height :75vh;
    width: 80%;
    border-radius: ${(props) => props.theme.borderRadius};
    #login-container{
        height:100%; 
    }
`;
export const SideContainer = styled(Column)`
    color: white;
    text-align: justify;
    line-height: 38px;
    font-size: ${(props) => props.theme.fontSizeLg};
`;

////////////////////////////////////
export const FormContainer = styled(Row)`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    border-radius: ${(props) => props.theme.borderRadius};
    form{
        width :100%;
        padding-top:20px;
    }
    .form-label{
        margin-bottom:10px;
        font-family: ${(props) => (props.theme.fontBold)};
    }
`;

