import styled, { css } from "styled-components";
import Row from "src/kit/Row";
import Column from "src/kit/Column";

;
export const FormContainer = styled(Column)`
padding : 10px 20px;


  .input-container input[type="text"] ,.input-container input[type="password"]{
    width: 100%;

  }

  

`;

