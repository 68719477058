
import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
NoDataToDisplay(Highcharts);

const ColumnChart = ({ title, series,height,years }) => {
    const [chartOptions, setChartOptions] = useState({
        title: {
            text: title
        },
        chart: {
            type: 'column',
            height,
            style :{
                fontFamily : 'inherit'
            },
            zoomType: 'xy',
        },
        lang: {
            noData: 'داده ای وجود ندارد',
          },
        colors: [
            '#FF9800',
            '#0F183F',
        ],
        subtitle: {
            text: ''
        },
    
        yAxis: {
            title: {
                text: ''
            }
        },
        xAxis: {
            categories: years
        },
        series: series,
        tooltip: {
            useHTML: true,
            borderColor: '#2c3e50',
            formatter: function () {
                return `
                <div style="text-align: right">
                <span>${this.series.name}</span><br/>
                </div>`;
            }
        },

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    
    });
       
    useEffect(() => {
       
        setChartOptions(prevState => ({
            ...prevState,
            series: series
        }))
    }, [series])
    useEffect(() => {
        setChartOptions(prevState => {
            return {
                ...prevState,
                ...{
                    xAxis: {
                        categories: years,
                    }
                }
            };
        })
    }, [years])
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions} />
    )
}

export default ColumnChart;