import styled from 'styled-components';
import Col from "src/kit/Column";
import Row from "src/kit/Row";

export const ImageWrapper = styled(Col)`
position: relative;
justify-content: flex-start;
width :45%;
padding :20px 0;
#icon_container{
    position: absolute;
    left: -150px;
    bottom: 50px;
    background-color :${(props) => props.theme.primaryDark};
    color :${(props) => props.theme.white};
    padding: 10px 20px;
    border-radius: 5px;
}
`;

export const ImageContainer = styled(Col)`
  justify-content: center;
  box-sizing: border-box;
  width: ${props => props.height ? props.height  :"120px"};
  height: ${props => props.height ? props.height  :"120px"};
  background:  url(${props => props.src});
  background-size : contain;
  background-repeat :  no-repeat;
  background-position: center;
  border-radius: 10px;
  background-color : #EDF1F1;
  border-radius : 50%;
`;

