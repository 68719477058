import React from "react";
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";
import DonutChart from "src/charts/DonutChart";
import subSystemMenuCrtl from "../dashboard.controllers"

const RequestStatus = () => {
  const { DonutChartData } = subSystemMenuCrtl();

    return (
            <ChartContainer>
                <TitleContainer paddingtop="20px">وضعیت درخواست ها</TitleContainer>
                <DonutChart height="400" series={DonutChartData} />
            </ChartContainer>
    )

}
export default RequestStatus