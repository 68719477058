import styled from "styled-components";
import Col from "src/kit/Column";
import Row from "src/kit/Row"
export const FullPageWrapper = styled(Col)`
  width: 100vw;
  height: 100vh;
  // background-color: ${(props) => props.backgroundColor || "#03002d"};
  background-color : ${(props) => props.theme.primaryLight};
`;
export const FullPageContainer = styled(Row)`
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  justify-content: space-between;
`;
export const ContentViewWrapper = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100%);
  height: 100%;
`;
export const BodyContainer = styled(Col)`
width: calc(100% - 200px);
 height: calc(100vh);
 background-color: #f4f4f4;
overflow-y : auto;
`
export const ContentViewContainer = styled(Row)`

  align-items: flex-start;
  width: calc(100%);
  // background-color: ${(props) => props.theme.primary};
  height: calc(100% - 110px);
  border-radius: ${(props) => props.theme.primaryBorderRadius};
  // margin-top: 25px;
  overflow-y: auto;

`;
