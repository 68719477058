export const rules = [
    {
        id: "1", text: `
   	پذیرش شرایط: با دسترسی یا استفاده از خدمات ارائه شده توسط پلاریس، شما موافقت می‌کنید که این شرایط و هر شرایط و ضوابط اضافی که ممکن است برای بخش‌های خاصی از خدمات اعمال شود را رعایت کنید. استفاده شما از خدمات به منزله پذیرش این شرایط و تغییرات آتی آن است. اگر با این شرایط موافق نیستید، امکان استفاده از خدمات وجود ندارد.
    `},
    {
        id: "2", text: `
    	 دسترسی به خدمات: پلاریس به شما مجوز محدود، غیر انحصاری، غیر قابل انتقال و قابل لغو برای دسترسی و استفاده از خدمات را برای اهداف شخصی یا داخلی کسب و کار شما اعطا می‌کند. شما نمی‌توانید این مجوز را بدون کسب موافقت کتبی از پلاریس، منتقل یا واگذار کنید. پلاریس این حق را برای اصلاح، تعلیق یا قطع خدمات یا هر بخشی از آن در هر زمان بدون اطلاع قبلی محفوظ می‌دارد.`},
    {
        id: "3", text: `
    	رفتار کاربر: شما موافقت می‌کنید که خدمات را فقط برای اهداف قانونی و مطابق با این شرایط استفاده نمایید. هنگام استفاده از خدمات، شما موافقت می‌نماییدکه تمامی قوانین، مقررات و آیین‌نامه‌های مربوطه را رعایت کنید. شما نباید اقداماتی که درخدمات، سرورها یا شبکه‌های متصل به خدمات تداخل یا اختلال ایجاد کند انجام دهید. فعالیت‌های ممنوعه شامل:
(الف) تلاش برای دسترسی غیرمجاز به خدمات یا حساب‌های دیگر.
(ب) استفاده از خدمات به هر نحوی که ممکن است به خدمات آسیب بزند، آن‌ها را غیرفعال کند، بار زیادی برای آن‌ها ایجاد کند و یا خدمات را مختل کند.
(ج) تظاهر به هر فرد یا موجودیت غیر از خود و یا آنچه مالکیت آن متعلق شماست.
(د) انتقال هرگونه مواد غیرقانونی، مضر، تهدید‌آمیز، توهین‌آمیز.
پلاریس این حق را برای بررسی و پیگیری نقض هر یک از موارد فوق تا جایی که این موارد به عنوان نقض قانون به طور کامل اعمال شود، محفوظ می‌دارد.

    ` 
    },
    {
        id: "4", text: `
    مالکیت فکری: تمام محتوا، نشان‌های تجاری، لوگوها و مالکیت فکری نمایش داده شده در وب‌سایت پلاریس متعلق به پلاریس می‌باشد. شما نمی‌توانید از هیچ‌کدام از محتوا بدون کسب موافقت کتبی از پلاریس استفاده کرده، یا اقدام به اصلاح، بازتولید، توزیع یا بهره‌برداری آن نمایید. شما تصدیق می‌نمایید که سرویس ها و هر نرم افزار مرتبطی که در ارتباط با سرویس ها استفاده می شود حاوی اطلاعات اختصاصی و محرمانه ای است که توسط مالکیت معنوی قابل اجرا است به وسیله این قوانین محافظت می‌شود.`},
    {
        id: "5", text: `
    	حریم خصوصی: حریم خصوصی شما برای ما مهم است. لطفاً سیاست حریم خصوصی پلاریس را برای درک نحوه جمع‌آوری، استفاده و افشای اطلاعات شما هنگام استفاده از خدمات پلاریس بررسی کنید.
    `},
    {
        id: "6", text: `
    	انکار ضمانت: خدمات بر اساس تعریفی که در سایت polaristech.ir ذکر شده است، بدون هیچ‌گونه ضمانتی ارائه می‌شود. ما ضمانت نمی‌کنیم که خدمات بی‌وقفه، به موقع، امن یا بدون خطا باشد، یا که هرگونه عیبی تصحیح شود.
    `},

    {
        id: "7", text: `
    	محدودیت مسئولیت: در هیچ حالتی پلاریس مسئولیت ضرر غیرمستقیم، اتفاقی، خاص، ناشی از عملکرد یا کیفیت پایین خدمات، یا هر از دست دادن سود یا درآمدی (مستقیم یا غیرمستقیم به دلیل استفاده شما از خدمات یا عدم دسترسی به خدمات) را نمی‌پذیرد. این محدودیت مسئولیت برای تمام ادعاهای مرتبط با این شرایط، چه بر اساس ضمانت، قرارداد، دعوی تقاضا، مسئولیت مدنی شده، یا هر نظریه حقوقی دیگری باشد، اعمال می‌شود.`},
    {
        id: "8", text: `
    بازپرداخت: هیچ یک از هزینه‌ها، مگر اینکه به طور صریح توافق شود، قابل بازپرداخت نیستند. در صورتی که پلاریس به شما خدماتی را ارائه داده باشد که با توصیف محصولی که خریداری کرده‌اید، مطابقت نداشته باشد، ممکن است شما حق بازپرداخت را داشته باشید. در صورت رخداد چنین شرایطی، لطفاً با ما تماس بگیرید
    `},
    {
        id: "9" ,text : `
        	تضمین: شما موافقت می‌کنید که پلاریس را از هر ادعا یا خواسته، از جمله هزینه‌های معقول وکلا، که به دلیل نقض شما از این شرایط یا نقض شما از هر قانون یا حقوق طرف سومی ناشی می‌شود، معاف کنید.`
    },
    {
        id: "10" ,text : `
        تغییرات در شرایط: پلاریس این حق را برای به‌روزرسانی یا تغییر این شرایط در هر زمان بدون اطلاع قبلی محفوظ می‌دارد. استفاده شما از خدمات پس از اعمال هر گونه تغییری به این شرایط نشان دهنده پذیرش شرایط تغییر یافته است. مسئولیت بررسی دوره‌هایی برای تغییرات این شرایط بر عهده شما است`
    },
    {
        id: "11" ,text : `
        	پایان: پلاریس ممکن است در هر زمان، با یا بدون دلیل، و بدون اطلاع قبلی، دسترسی شما به خدمات را متوقف یا معلق کند. پس از فسخ، حق استفاده شما از خدمات به طور فوری متوقف می‌شود. تمام مفاد این شرایط که طبیعتاً باید پس از پایان فسخ باقی بمانند، شامل، سلب مسئولیت ضمانت‌نامه، مفاد مالکیت، غرامت و محدودیت‌های مسئولیت بدون محدودیت باقی می‌مانند.`
    },
    {
        id: "12" ,text : `
        قانون حاکم: این شرایط بدون توجه به مغایرت با مقررات قانونی جمهوری اسلامی ایران، مطابق با قوانین جمهوری اسلامی ایران تنظیم و تفسیر می شود.`
    },
    {
        id: "13" ,text : `
        حل اختلاف: هر اختلافی ناشی از یا مربوط به این شرایط یا خدمات باید در ابتدا از طریق مذاکره بین طرفین حل شود، و در صورت عدم توانایی در رسیدن به توافق، باید از طریق مراجع قضایی صلاحیت‌دار در تهران، جایی که محل قانونی پلاریس قرار دارد، بررسی و حل شود.`
    },
    {
        id: "14" ,text : `
        تماس با ما: اگر سوالی در مورد این شرایط دارید، لطفاً با ما تماس بگیرید. با استفاده از خدمات پلاریس، شما تصدیق می‌کنید که این شرایط را خوانده، فهمیده و موافقت می‌کنید که به آنها متعهد شوید.`
    }


]