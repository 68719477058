import React,{useState} from "react";
import { FormContainer } from "./styles";

import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button"
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "src/redux/Login/Actions"
import { Grid, Box } from "@mui/material";
import { ToastContainer } from 'react-toastify';
import RulesModal from "src/views/modal/rulesModal";
import {VerifyValidationRegister} from "src/validation/schemas/verifyValidationRegister"
import { toast } from 'react-toastify';

const RegisterForm = ({ setMode }) => {
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const onUserRegister = (values, action, callBack) => {
        dispatch(LoginActions.register({ values, action, callBack }))
    }
    const showRulesModal = useSelector((state) => state.Login.showRulesModal);

    const showRulesModalHandler = (visible) => {
        dispatch(LoginActions.setShowRulesModal({ visible }))

    }
    return (
        <FormContainer>
            <Formik
                initialValues={{
                    email: "",
                    orgName: "",
                    password: "",
                    confirmPassword: "",
                    rule: false

                }}
                onSubmit={(values, action) => {
                    let callBack = () => {
                        setMode("login")
                    };
                    if(values.rule){

                        onUserRegister(values, action, callBack);
                    }
                    else{
                        toast.warn("ثبت نام شما تنها در صورت پذیرش قوانین و مقررات امکان‌پذیر است.",
                         {position:toast.POSITION.BOTTOM_RIGHT});
                    }
                }}
            validationSchema={VerifyValidationRegister}
            >
                {(formik) => (
                    <form className="" onSubmit={formik.handleSubmit}>
                        <Grid container>
                            <Grid p={1} item xs={12} className="form_title">
                                ثبت نام
                            </Grid>
                            <Grid p={1} item xs={12} display="flex" justifyContent="start">
                                <Box>آیا قبلا ثبت نام کرده اید؟ </Box><Box px={1} color="#1F499F" className="clickable" onClick={() => { setMode("login") }}>ورود</Box>
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <Box >
                                    <Box className='form-label'>{"ایمیل"}</Box>
                                    <FormInput
                                        type="text"
                                        className={
                                            "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                        }
                                        value={formik.values.email}
                                        id="email"
                                        name="email"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={"ایمیل"}
                                    />
                                    {formik.errors.email && formik.touched.email ? (
                                        <div className="text-danger">{formik.errors.email}</div>
                                    ) : null}
                                </Box>
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <Box>
                                    <Box className='form-label'>{"نام سازمان"}</Box>
                                    <FormInput
                                        type="text"
                                        className={
                                            "login-form-control" + (formik.errors.orgName && formik.touched.orgName ? " warning-border" : "")
                                        }
                                        value={formik.values.orgName}
                                        id="orgName"
                                        name="orgName"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={"نام سازمان"}
                                    />
                                </Box>
                            </Grid>
                    
                            <Grid item xs={12} p={1}>
                                <Box >
                                    <Box className='form-label'>{"رمز عبور"}</Box>
                                    <FormInput
                                        type="password"
                                        className={
                                            "login-form-control" + (formik.errors.password && formik.touched.password ? " warning-border" : "")
                                        }
                                        value={formik.values.password}
                                        id="password"
                                        name="password"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={"رمز عبور خود را وارد نمایید"}
                                    />
                                    {formik.errors.password && formik.touched.password ? (
                                        <div className="text-danger">{formik.errors.password}</div>
                                    ) : null}
                                </Box>
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <Box >
                                    <Box className='form-label'>{"تکرار رمز عبور"}</Box>
                                    <FormInput
                                        type="password"
                                        className={
                                            "login-form-control" + (formik.errors.confirmPassword && formik.touched.confirmPassword ? " warning-border" : "")
                                        }
                                        value={formik.values.confirmPassword}
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={"رمز عبور خود را مجددا وارد نمایید"}
                                    />
                                    {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                                        <div className="text-danger">{formik.errors.confirmPassword}</div>
                                    ) : null}
                                </Box>
                            </Grid>
                            <Grid item sm={12} px={1} pt={3} display="flex" justifyContent="start">
                                <Box color="#CCCCCC">
                                    <input
                                        type="checkbox"
                                        id="rule"
                                        name="rule"
                                        onChange={(e) => {
                                            FormController.onChangeCheckbox(e, formik, null);
                                            setIsChecked(e.target.checked);
                                        }}
                                        checked={formik.values.rule}
                                    />
                                </Box>
                                <Box px={1} onClick={() => { setMode("register") }}>
                                    <span className="clickable" style={{color:"#1F499F"}} onClick={() => showRulesModalHandler(true)}>قوانین و شرایط</span> را مطالعه نموده و پذیرفته ام.
                                </Box>
                            </Grid>

                            <Grid item xs={12} p={1}>
                                <Button
                                    onClick={() => formik.handleSubmit()}
                                    text="ثبت نام"
                                    disabled={!isChecked}
                                >
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
            <ToastContainer rtl />
            <RulesModal
                title="قوانین و مقررات"
                open={showRulesModal}
                onClose={showRulesModalHandler}
            />
        </FormContainer>
    )
}
export default RegisterForm