import React, { useState ,useEffect} from "react";
import ReactDOM from "react-dom";

import "./styles.css";

const Button = (props) => {
    const {ButtonLoading,onConfirm,disabled =false} = props;
  const [isLoading, setIsLoading] = useState(ButtonLoading);
  useEffect(() => {
    setIsLoading(ButtonLoading);
  }, [ButtonLoading]);
  const handleOnClick = () => {
   if(onConfirm) onConfirm();
    if (isLoading) return;

    setIsLoading(true);
   // setTimeout(() => setIsLoading(false), 2000);
  };

  return (
    <button
      // className={isLoading ? "loading" : undefined isChecked ? "" : "disabled-button"}
      className={`${isLoading ? "loading" : ""} ${disabled === false ? "" : "disabled-button"}`}
      onClick={onConfirm}
      disabled={disabled}
    >
      <div className="spinner" />
      <p className="text">{props.text}</p>
    </button>
  );
}
export default Button;