import React from "react";
import { Modal, Box, Grid } from "@mui/material";
import CloseIcon from "src/assets/icons/CloseIcon";
import { rules } from "./helpers";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};

const RulesModal = ({
    open,
    onClose,
    title
}) => {

    //overflowY : "scroll",
    return (
        <Modal
            open={open}
            onClose={() => { onClose(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box sx={style}>
                {title &&
                    <Grid item container xs={12} py={2} sx={{ borderBottom: 1, borderColor: 'grey.500' }}>
                        <Grid item xs={11} display="flex" justifyContent="start">
                            {title}
                        </Grid>
                        <Grid item xs={1} onClick={() => onClose(false)} className="clickable"
                            display="flex" justifyContent="end"
                        >
                            <CloseIcon />
                        </Grid>
                    </Grid>}
                <Grid container style={{ height: 'calc(100% - 50px)' }}>

                    <Grid item xs={12} p={3} style={{ height: "100%" ,overflowY: "scroll",textAlign:"justify" }}>
                    به پلاریس خوش آمدید! این شرایط و ضوابط دسترسی شما به استفاده از وب‌سایت و خدمات پلاریس را کنترل می‌کنند. با دسترسی یا استفاده از خدمات، شما موافقت می‌کنید که به این شرایط متعهد شوید. اگر با هر بخشی از این شرایط مخالف باشید، امکان دسترسی به خدمات وجود ندارد
                        {
                            rules.map((each, index) => {
                                return (
                                    <p key={index} style={{textAlign:"justify"}} >
                                        {each.id}- {each.text}
                                    </p>
                                )

                            })
                        }
                    </Grid>

                </Grid>

            </Box>
        </Modal>
    )
}
export default RulesModal;