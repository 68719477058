import styled from "styled-components";
import Column from "src/kit/Column";
import Row from "src/kit/Row";

export const FormRow = styled(Column)`
   width : 100%;
   padding:0 10px;
   padding-bottom :0;
   box-sizing : border-box;
   .text-alert{
      color : red;
      font-size: 10px;
      width: 100%;
      height :20px;
   }
`;
export const FilterContainer = styled(Row)`
   width : 100%;
   padding-bottom :0;
   box-sizing : border-box;
   .filter-box{
      width : 100%;
      justify-content: flex-start;
   }
   .btn-filter{
      font-family: ${(props) => (props.theme.fontDefault)};
      padding:5px;
      background-color : ${props => props.theme.primary};
      color:${props => props.theme.white}; 
      width : 100px;
      border:none;
      height :35px;
      cursor : pointer;
    }
`;

export const FormContainer = styled(Column)`
  padding : 10px 20px;
  border-top :1px solid #ccc;
  form {
   width: 100%;
  }

`;
export const StatusContainer = styled(Column)`
width: 80%;
background: #F0F0F0;
align-items: end;
height: 30px;
position : relative;
 #inner{
   background-color : ${props => props.theme.primary};
   width :  ${props => props.width *100}%;
   color :  ${props => props.theme.white};
   height: 100%;
 }
#number{
   position : absolute;
   left: 45%;
}
`;
export const TableActions = styled(Column)`
 .table_btn{
   border :1px solid #D9D9D9;
   padding: 0 10px;
   border-radius:5px;
   color : #ABAFB3;
   cursor :pointer;
 }
`;

export const DetailContainer = styled(Column)`
overflow-y: scroll;
width: 100%;
padding: 20px;
box-sizing: border-box;
background-color: #fff;
height: calc(100% - 50px);
box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.10);
margin-bottom :20px;
justify-content: flex-start;
.box-container{
   border :1px solid #D9D9D9;
   padding: 10px;
   border-radius:5px;
   color : #ABAFB3;
   box-sizing: border-box;
   display :flex;
   justify-content : center;
   display: flex;
    flex-direction: column;
    min-height:130px;
 }
 .text_bold{
  color : ${props => props.theme.primaryDark};
  font-family: ${(props) => (props.theme.fontBold)};
  font-size : ${(props) => (props.theme.fontSizeXl)};
 }
`;