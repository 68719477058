import React, { useState } from "react";
import { Modal, Box, Grid } from "@mui/material";
import Button from "src/kit/Button"
import LoginActions from "src/redux/Login/Actions";
import ConfirmationIcon from "src/assets/icons/ConfirmationIcon"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};

const SendEmailModal = ({
    open,
    text,
    onClose,
}) => {

    return (
        <Modal
            open={open}
            onClose={() => {onClose(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box sx={style}>
                <Grid container>
                    <Grid item xs={12} p={1} display="flex" justifyContent="center">
                        <ConfirmationIcon />
                    </Grid>
                    <Grid item xs={12} p={1} display="flex" justifyContent="center">
                        {text}
                    </Grid>
                </Grid>
                <Grid item xs={12} p={1}>
                    <Button
                        onConfirm={() => {onClose(false)}}
                        text="تایید"
                    >
                    </Button>
                </Grid>
            </Box>
        </Modal>
    )
}
export default SendEmailModal;