import React from "react";
import { Grid,Box } from "@mui/material";
import {  CardContainer } from "../styles"
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";
import LeftArrowIcon from "src/assets/icons/LeftArrowIcon"
import { Link } from "react-router-dom";
import subSystemMenuCrtl from "../dashboard.controllers"

const RecentEndPoint = () => {
  const { statisticsUsedEndpoint  } = subSystemMenuCrtl();

    const data = [
        { id: 0 },
        { id: 1 },
        { id: 2 },
        { id: 3 },
    ]
    const data1 =
    "iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA2SURBVHgB7dFBEQAgDAPBBH/1AMrAQwWWQUM+zDT7OAMHmIovETFJbgiqamXmGTCVp3zIUzq4EbsYCo0/TcMAAAAASUVORK5CYII=";

    return(
        <ChartContainer>
        <Grid container>
            <Grid item xs={12} display="flex" justifyContent="space-between" p={2}>
                <TitleContainer paddingtop="0px">آخرین نقاط اتصال فعال </TitleContainer>
                <Link to="./endpoint">
                    <Box fontSize={17} color="#1F499F" display="flex" alignItems="center"> مشاهده همه نقاط اتصال <Box display="flex" pr={1}><LeftArrowIcon /></Box></Box>
                </Link>
            </Grid>
            {statisticsUsedEndpoint.length > 0 && statisticsUsedEndpoint.map((each, index) => {
                return (
                    <Grid item xs={12} md={6} lg={3} key={each.id} p={2}>
                        <CardContainer>
                              <img src={`data:image/jpeg;base64,${each.logo}`} />
                            <Box className="box-title">{each.name}</Box>
                            <Box>
                                <Box my={1}>درخواست موفق (24 ساعت) : {each.lastDaySuccessCount}</Box>
                                <Box my={1}>درخواست ناموفق (24 ساعت) : {each.lastDayFailedCount}</Box>
                                <Box my={1}>درخواست موفق (1 هفته) : {each.lastWeekSuccessCount}</Box>
                                <Box my={1}>درخواست ناموفق (1 هفته) : {each.lastWeekFailedCount}</Box>
                                <Box my={1}>درخواست موفق (1 ماه) : {each.lastMonthSuccessCount}</Box>
                                <Box my={1}>درخواست ناموفق (1 ماه) : {each.lastMonthFailedCount}</Box>
                            </Box>

                        </CardContainer>
                    </Grid>
                )
            })}
        </Grid>
    </ChartContainer>
    )
}
export default RecentEndPoint