import styled, { css } from "styled-components";
import Row from "src/kit/Row";
import Column from "src/kit/Column";

;
export const LoginFormContainer = styled(Row)`
    height :65vh;
    position:absolute;
    width: 60%;
    right: 12%;
    top: 11%;
    background-color: white;
    border-radius: ${(props) => props.theme.borderRadius};
    #login-container{
        height:100%; 
    }
`;
export const LogoContainer = styled(Column)`
    background-color: #F5F5F5;
    width:100%;
    height:100%;
    justify-content: space-around;
    border-radius:0 ${(props) => props.theme.borderRadius} ${(props) => props.theme.borderRadius} 0;
`;

////////////////////////////////////
export const FormContainer = styled(Row)`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    form{
        width :100%;
        padding-top:20px;
    }
    .form-label{
        margin-bottom:10px;
        font-family: ${(props) => (props.theme.fontBold)};
    }
`;

