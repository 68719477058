import React from 'react';
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormContainer } from "./styles";
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button"
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "src/redux/Login/Actions"
import { Grid, Box } from "@mui/material";
import { ToastContainer } from 'react-toastify';
import ChangePasswod from "src/views/modal/forgetPassword";
import Storage from "src/storage";
import Keys from "src/storage/NameSpace";
import {VerifyValidationLogin} from "src/validation/schemas/verifyValidationLogin"


const LoginForm = ({ setMode }) => {
    const showforgetPasswordModal = useSelector((state) => state.Login.showforgetPasswordModal);

    const dispatch = useDispatch();
    const onUserLogin = (values, action) => {
        dispatch(LoginActions.login({ values, action }))
    }
    const onConfirmChangePassword = (value) => {
        dispatch(LoginActions.forgetPassword({ value }))
    }
    return (
        <>
            <FormContainer>
                <Formik
                    initialValues={{
                        email: Storage.get(Keys.email) ? Storage.get(Keys.email) : "",
                        password: "",
                        rememberMe: Storage.get(Keys.rememberMe) ? Storage.get(Keys.rememberMe) : false,
                    }}
                    onSubmit={(values, actions) => {

                        if (values.rememberMe === true) {
                            Storage.set(Keys.email, values.email);
                            Storage.set(Keys.rememberMe, values.rememberMe);
                        }
                        else {
                            Storage.set(Keys.email, "");
                            Storage.set(Keys.rememberMe, values.rememberMe);
                        }

                        onUserLogin(values, actions);
                    }}
                // validationSchema={VerifyValidationLogin}
                >
                    {(formik) => (
                        <form className="" onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid item xs={12} p={1}>
                                    <Box>
                                        <Box className='form-label'>آدرس ایمیل</Box>
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                            }
                                            value={formik.values.email}
                                            id="email"
                                            name="email"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="ایمیل خود را وارد نمایید"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Box>
                                        <Box display="flex" justifyContent="start">
                                            <Box className='form-label'>رمز عبور</Box>
                                        </Box>

                                        <FormInput
                                            type="password"
                                            className={
                                                "login-form-control" + (formik.errors.password && formik.touched.password ? " warning-border" : "")
                                            }
                                            value={formik.values.password}
                                            id="password"
                                            name="password"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="رمز عبور خود را وارد نمایید"
                                        />
                                        <Box display="flex" justifyContent="end">
                                            <Box color="#1F499F" className="clickable" onClick={() => dispatch(LoginActions.forgetPasswordModal({ visible: true }))}>رمز عبور خود را فراموش کرده اید</Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} px={1} pt={3} display="flex" justifyContent="start">
                                    <Box color="#CCCCCC">

                                        <input
                                            type="checkbox"
                                            id="rememberMe"
                                            name="rememberMe"
                                            onChange={(e) => FormController.onChangeCheckbox(e, formik, null)}
                                            checked={formik.values.rememberMe}
                                        />

                                    </Box>
                                    <Box px={1} className="clickable" onClick={() => { setMode("register") }}>
                                        مرا به خاطر بسپار
                                    </Box>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Button
                                        onClick={() => formik.handleSubmit()}
                                        text="ورود"
                                    >
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item sm={12} p={1} display="flex" justifyContent="start">
                                <Box color="#CCCCCC">تاکنون ثبت نام نکرده‌اید؟  </Box>
                                <Box px={1} className="clickable" onClick={() => { setMode("register") }}>
                                    ثبت نام
                                </Box>
                            </Grid>
                        </form>
                    )}
                </Formik>
                <ToastContainer rtl />
                <ChangePasswod open={showforgetPasswordModal} onConfirm={onConfirmChangePassword} />
            </FormContainer>
        </>
    )
}
export default LoginForm;