import React, { useRef, useState } from "react";
import { Modal, Box, Grid } from "@mui/material";
import ConfirmationIcon from "src/assets/icons/ConfirmationIcon"
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button";
import CopyIcon from "src/assets/icons/CopyIcon";
import TickIcon from "src/assets/icons/TickIcon";

import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};

const SendEmailModal = ({
    open,
    apiKey,
    onClose,
}) => {

    const inputRef = useRef(null);
    const [isCopied , setIsCopied] = useState(false);

    const handleCopy = async () => {
        const input = inputRef.current;
        if (input) {
            await navigator.clipboard.writeText(input.value);
            setIsCopied(true);
            toast.success("با موفقیت کپی شد.", { position: toast.POSITION.BOTTOM_RIGHT });

        }
    };

    return (
        <Modal
            open={open}
            onClose={() => { onClose(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box sx={style}>
                <Grid container>
                    <Grid item xs={12} p={1} display="flex" justifyContent="center">
                        <ConfirmationIcon />
                    </Grid>
                    <Grid item xs={12} p={1} container>
                        <Grid item xs={2}  onClick={handleCopy} className="clickable" display="flex" alignItems="center">
                            {isCopied === false ? <CopyIcon /> : <TickIcon /> }
                        </Grid>
                        <Grid item xs={10}  >
                            <Box >
                                <FormInput
                                    ref={inputRef} 
                                    type="text"
                                    direction="ltr"
                                    textAlign="center"
                                    value={apiKey}
                                    id="api_key"
                                    name="api_key"
                                    disabled
                                />

                            </Box>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} p={1}>
                        به دلیل عدم ذخیره سازی در سامانه، در صورت مفقود شدن، api-key جدید برای شما ساخته می‌شود.
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <Button
                            onConfirm={() => { onClose(false) }}
                            text={"دریافت"}
                        >
                        </Button>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
    )
}
export default SendEmailModal;