import React, { useEffect, useState } from "react";
import { Modal, Box, Grid } from "@mui/material";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button"
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "src/redux/Login/Actions";
import CloseIcon from "src/assets/icons/CloseIcon";
import {FormContainer } from  './styles';
import EditIcon from "src/assets/icons/EditIcon";
import UserActions from "src/redux/User/Actions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    // p: 2,
};

const ProfileModal = ({
    open,
    onClose,
    title
}) => {
    const dispatch = useDispatch();
    const [selectedImage, setSelectedImage] = useState(null);

    const userState = useSelector((state) => state.User);
    const {userInfo} = userState;
    

    return (
        <Modal
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            
            <Box sx={style}>
                <Grid item container xs={12} id="modal_title" p={2}>
                    <Grid item xs={11} display="flex" justifyContent="start">
                        {title}
                    </Grid>
                    <Grid item xs={1} onClick={() => onClose(false)} className="clickable">
                        <CloseIcon />
                    </Grid>
                </Grid>
                <FormContainer>
             
                <Formik
                    initialValues={{
                        currentPassword: "",
                        newPassword: "",
                        confirmPassword: ""
                    }}
                    onSubmit={(values, action) => {
                        dispatch(UserActions.changePassword({values : values }));
                    }}
                >
                    {(formik) => {
                        return (
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12}  py={1}>
                                        <FormInput
                                            type="password"
                                            className={
                                                "login-form-control" + (formik.errors.currentPassword && formik.touched.currentPassword ? " warning-border" : "")
                                            }
                                            value={formik.values.currentPassword}
                                            id="currentPassword"
                                            name="currentPassword"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="رمز عبور فعلی"
                                        />
                                    </Grid>
                                    <Grid item xs={12}  py={1}>
                                        <FormInput
                                            type="password"
                                            className={
                                                "login-form-control" + (formik.errors.newPassword && formik.touched.newPassword ? " warning-border" : "")
                                            }
                                            value={formik.values.newPassword}
                                            id="newPassword"
                                            name="newPassword"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="رمز عبور جدید"
                                        />
                                    </Grid>
                                    <Grid item xs={12}  py={1}>
                                        <FormInput
                                            type="password"
                                            className={
                                                "login-form-control" + (formik.errors.confirmPassword && formik.touched.confirmPassword ? " warning-border" : "")
                                            }
                                            value={formik.values.confirmPassword}
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="تکرار رمز عبور جدید"
                                        />
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                    <Button
                                        onClick={() => formik.handleSubmit()}
                                        text="ذخیره"
                                    >
                                    </Button>
                                </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
           
                </FormContainer>

            </Box>
        </Modal>
    )
}
export default ProfileModal;