import react from "react";
import { RegisterFormContainer, SideContainer } from "./styles"
import { Grid, Box } from "@mui/material";
import RegisterForm from "./registerForm";
import Logo from "src/assets/icons/LogoIcon1"

const Register = ({ setMode }) => {
    return (
        <RegisterFormContainer>
            <Grid container id="register-container">
                <Grid item xs={12} md={7} p={2}>
                    <SideContainer>
                        <Grid container>
                            <Grid item xs={12} >
                                <Logo />
                            </Grid>
                            <Grid item xs={12} py={2}>
                                بلاک‌چین به صورت سرویس (BaaS) یک سرویس پلتفرم در سطح سازمانی است که بر اساس فناوری‌های پیشرو در بلاک‌چین است. این خدمت به شرکت‌هایی که در زمینه ساخت برنامه‌های بلاک‌چین فعالیت می‌کنند، کمک می‌کند تا یک محیط بلاک‌چین امن و پایدار بسازند، در هزینه‌های عملیات و نگهداری صرفه جویی کنند و توسعه کسب و کارشان را تسهیل نماید.
                            </Grid>
                            <Grid item xs={12}>
                                درواقع پلاریس مانند نوعی میزبان وب عمل می‌کند که عملیات زیرساخت را برای یک برنامه یا پلتفرم مبتنی بر بلاک‌چین اجرا می‌کند. در واقع بلاک‌چین به صورت سرویس(BaaS) می‌تواند در نقش کاتالیزوری باشد که منجر به پذیرش گسترده فناوری بلاک‌چین می‌شود.
                            </Grid>
                        </Grid>
                    </SideContainer>
                </Grid>
                <Grid item xs={12} md={5} p={2}>
                    <RegisterForm setMode={setMode} />
                </Grid>
            </Grid>

        </RegisterFormContainer>
    )
}
export default Register