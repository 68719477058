import React from "react";
import { Modal, Box, Grid , useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput} from "src/kit/Form";
import CloseIcon from "src/assets/icons/CloseIcon";
import {FormContainer} from "../styles";
import { useSelector , useDispatch} from "react-redux";
// import { VerifyValidationMenu } from "src/validation/schemas/verifyValidationMenu";
import VirtualizedSelect from 'react-virtualized-select';
import EndpointsActions from "src/redux/Endpoint/Actions";

const endpointTypeOptions  = [
    { value: 0, label: "Http (JsonRPC)" },
    { value: 1, label: "Web Socket" }
]

const EndpointDetailModal = (props) => {
    const { show,
        onclose,
        title,
        fields,
        onConfirm,
    } = props;
    const theme= useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const endpointState = useSelector((state) => state.Endpoint);
    const { endpointsOptions ,networksListOptions } = endpointState;
    const dispatch = useDispatch();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? "90vw" : "60vw",
        height : isSmallScreen ? "auto" : "auto",
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
    };

    return (
        <Formik
            initialValues={{ ...fields }}
            isInitialValid={false}
            // validationSchema={VerifyValidationMenu}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                onConfirm(values, action);
            }}
        >
            {(formik) => (

                <Modal
                    open={show}
                    onClose={() => onclose(formik)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                    BackdropProps={{ onClick: (e) => e.stopPropagation() }}
                >
                    <Box sx={style}>
                        <Grid item container xs={12} id="modal_title" p={2}>
                            <Grid item xs={11} display="flex" justifyContent="start">
                                {title}
                            </Grid>
                            <Grid item xs={1} onClick={() => onclose(formik)} className="clickable" display="flex" justifyContent="end">
                                <CloseIcon />
                            </Grid>
                        </Grid>
                        <FormContainer>
                            <form className="py-5" onSubmit={formik.handleSubmit}>
                                <Grid container display="flex" justifyContent="end">
                                    
                                <Grid item p={1}  xs={12} md={6}>
                                        <Box className="form_lable">نقطه اتصال</Box>
                                        <VirtualizedSelect
                                            value={formik.values.blockchain}
                                           noResultsText="موردی وجود ندارد"
                                            name="blockchain"
                                            id="blockchain"
                                            placeholder="انتخاب کنید"
                                            onChange={(selected) => {
                                                FormController.onSelect(selected, "blockchain", formik, null, true);
                                                // dispatch(EndpointsActions.getNetworksList({id : selected.value}))
                                            }}
                                            maxHeight={150}
                                            options={endpointsOptions}
                                            multi={false}
                                            className={
                                                "custom_input" + (formik.errors.blockchain && formik.touched.blockchain ? " warning-bottom-border" : "")
                                            }
                                            backspaceToRemoveMessage=""
                                        />
                                        {formik.errors.blockchain && formik.touched.blockchain ? (
                                            <div className="text-alert">{formik.errors.blockchain}</div>
                                        ) : <div className="text-alert"></div>}
                                    </Grid>
                                    {/* <Grid item p={1}  xs={12} md={6}>
                                        <Box className="form_lable">شبکه</Box>
                                        <VirtualizedSelect
                                            value={formik.values.network}
                                           noResultsText="موردی وجود ندارد"
                                            name="network"
                                            id="network"
                                            placeholder="انتخاب کنید"
                                            onChange={(selected) => {

                                                FormController.onSelect(selected, "network", formik, null, true);
                                            }}
                                            maxHeight={150}
                                            options={networksListOptions}
                                            multi={false}
                                            className={
                                                "custom_input" + (formik.errors.network && formik.touched.network ? " warning-bottom-border" : "")
                                            }
                                            backspaceToRemoveMessage=""
                                        />
                                        {formik.errors.network && formik.touched.network ? (
                                            <div className="text-alert">{formik.errors.network}</div>
                                        ) : <div className="text-alert"></div>}

                                    </Grid> */}

                                    <Grid item p={1}  xs={12} md={6}>
                                        <Box className="form_lable">نام</Box>
                                        <FormInput
                                            value={formik.values.title}
                                            name="title"
                                            id="title"
                                            placeholder="نام"
                                            onChange={(e) => FormController.onChange(e, formik, null)}
                                            className={
                                                "custom_input" + (formik.errors.title && formik.touched.title ? " warning-border" : "")
                                            }
                                        />
                                        {formik.errors.title && formik.touched.title ? (
                                            <div className="text-alert">{formik.errors.title}</div>
                                        ) : <div className="text-alert"></div>}

                                    </Grid>
                                    {/* <Grid item p={1}  xs={12} md={6}>
                                        <Box className="form_lable">نوع</Box>
                                        <VirtualizedSelect
                                        noResultsText="موردی وجود ندارد"
                                            value={formik.values.endpointType}
                                            name="endpointType"
                                            id="endpointType"
                                            placeholder="انتخاب کنید"
                                            onChange={(selected) => {
                                                FormController.onSelect(selected, "endpointType", formik, null, true);
                                            }}
                                            maxHeight={150}
                                            options={endpointTypeOptions}
                                            multi={false}
                                            className={
                                                "custom_input" + (formik.errors.endpointType && formik.touched.endpointType ? " warning-bottom-border" : "")
                                            }
                                            backspaceToRemoveMessage=""
                                        />
                                        {formik.errors.endpointType && formik.touched.endpointType ? (
                                            <div className="text-alert">{formik.errors.endpointType}</div>
                                        ) : <div className="text-alert"></div>}
                                    </Grid> */}
            

                                <Grid item xs={4} p={1} md={4} >
                                    <button
                                        color="primary"
                                        size="lg"
                                        onClick={formik.handleSubmit}
                                        className="ml-1 btn-form"
                                    >
                                        <Box className="color-white" p={2}>ثبت</Box>
                                    </button>
                                </Grid>
                                </Grid>
                            </form>
                            </FormContainer>
                    </Box>
                </Modal>
            )}
        </Formik>
    )
}
export default EndpointDetailModal