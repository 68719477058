import React, { useEffect, useState } from "react";
import { FirstLoginWrapper, FirstLoginContainer, TitleConatiner } from "./styles";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button"
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import UploadImage from "src/shared/uploadImage";
import UserActions from "src/redux/User/Actions";
import LoginActions from "src/redux/Login/Actions";



const FirstLogin = () => {
    const dispatch = useDispatch();
    const [selectedImage, setSelectedImage] = useState(null);
    const userApiKey = useSelector((state) => state.User.userApiKey);
    useEffect(() => {

    }, [userApiKey])

    const handleCompleteInfo = () => {
        dispatch(LoginActions.completeInfo());
    }


    return (
        <FirstLoginWrapper>
            <FirstLoginContainer>
                <TitleConatiner>
                    سازمان شما
                </TitleConatiner>

                <Formik
                    initialValues={{
                        orgName: "",
                        apiKey:""
                    }}
                    onSubmit={(values, action) => {
                        // dispatch(UserActions.generateApiKey({ fields: values, selectedImage: selectedImage ,action:action }));
                    }}
                >
                    {(formik) => {
                        return (
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container px={5}>
                                    <Grid item xs={12}>
                                        <UploadImage selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                                    </Grid>
                                    <Grid item xs={12} px={2}>
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.orgName && formik.touched.orgName ? " warning-border" : "")
                                            }
                                            value={formik.values.orgName}
                                            id="orgName"
                                            name="orgName"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="نام سازمان"
                                        />
                                    </Grid>
                                    <Grid item xs={12} px={2} py={1} id="api_key_btn">
                                        <Button
                                            onClick={() => formik.handleSubmit()}
                                            text="API Key جدید"
                                            type="submit"
                                        >
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} px={2}>
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.apiKey && formik.touched.apiKey ? " warning-border" : "")
                                            }
                                            value={userApiKey}
                                            id="apiKey"
                                            name="apiKey"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder=""
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} px={2} pt={5}>
                                        <Button
                                            onConfirm={() => handleCompleteInfo()}
                                            text="ذخیره"
                                        >
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>

            </FirstLoginContainer>

        </FirstLoginWrapper>

    )
}
export default FirstLogin;
