import styled, { css } from "styled-components";
import Row from "src/kit/Row";
import Column from "src/kit/Column";

;
export const FormContainer = styled(Column)`
padding : 10px 20px;
.input-container {
    position: relative;
    width: 91%; /* Adjust width as needed */
    margin: 3px auto; /* Adjust margin as needed */
  }

  .input-container input[type="text"] ,.input-container input[type="password"]{
    width: 100%;
    padding: 10px 100px 10px 10px; /* Adjust padding to accommodate icon */
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
  }

  .input-container .icon {
    position: absolute;
    left: 10px; /* Adjust position of icon */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .input-container .placeholder-text {
    position: absolute;
    right: 10px; /* Adjust position of placeholder text */
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }
  border-top :1px solid #ccc;
  border-bottom :1px solid #ccc;

`;

export const ExitContainer = styled(Row)`
padding :20px;

`;
