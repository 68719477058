import styled from "styled-components";
import Row from "src/kit/Row";
import Col from "src/kit/Column";

export const ChartContainer = styled.div`
  width: 100%;
  min-height : ${(props) => props.height || "300px"};
  background-color:  ${(props) => (props.theme.primaryLight)} ;
  box-sizing: border-box;
  box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
  margin-bottom:20px;

`;

export const IconContainer = styled(Row)`
  cursor: pointer;
  width: ${(props) => props.size || "15px"};
  height: ${(props) => props.size || "15px"};
  svg {
    width: ${(props) => props.size || "15px"};
    height: ${(props) => props.size || "15px"};
  }
`;
export const ActionIconsContainer = styled(Row)`
border :1px solid #ccc;
padding:10px;
`;

export const ActionIconsItem = styled(IconContainer)`
  margin: 0 5px;
`;

export const CardContainer = styled(Col)`
    width:96%;
    justify-content:end;
    height : 100%;
    align-items: center;
    padding: 10px 0px;
    box-sizing: border-box;
`;
export const TitleContainer = styled(Row)`
  color : ${props => props.theme.primaryDark};
  font-family: ${(props) => (props.theme.fontBold)};
  font-size : ${(props) => (props.theme.fontSizeLg)};
  padding-top: ${(props) => (props.paddingtop ? props.paddingtop : "0px")};
`;
export const AddContainer = styled(Row)`
    width:100%;
    justify-content:end;
    height:60px;
    span {
        display:flex;
        justify-content:center;
        align-items: center;
        width :100%;
        height:40px;
        font-size: ${(props) => props.theme.fontSizeLg};
        color: ${(props) => props.theme.white};
        background-color :${(props) => props.theme.primaryDark};
        border-radius : ${(props) => props.theme.borderRadius};
        cursor : pointer;
        padding: 0 10px;
    }
`;


export const TableWrapper = styled(Col)`
width: 100%;
padding: 20px;
    box-sizing: border-box;
    background-color : #fff;
height: ${(props) => props.height || "auto"};
box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
table {
    border-collapse:separate; 
    border-spacing: 0 1em;
  }
table td{
    font-family: ${(props) => (props.theme.fontDefault)};
    color: ${(props) => props.theme.text};
    border:none;
    background-color : #f1f2f3;
}
table th{
    font-family: ${(props) => (props.theme.fontDefault)};
    color: ${(props) => props.theme.secondaryFontColor};
    border:none;
    background-color : #e0e6ed;
}
tr td:first-child , tr th:first-child{
    border-top-right-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-bottom-right-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-top-left-radius: ${(props) => props.lang === "en" ? "10px" : "0"}; 
    border-bottom-left-radius: ${(props) => props.lang === "en" ? "10px" : "0"};
   }
tr td:last-child , tr th:last-child { 
    border-top-left-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-bottom-left-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-top-right-radius: ${(props) => props.lang === "en" ? "10px" : "0"};
    border-bottom-right-radius: ${(props) => props.lang === "en" ? "10px" : "0"};  
}

button{
    height: 100%;
    background-color: ${(props) => props.theme.white};
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid ${(props) => props.theme.secondaryFontColor};;
    width: 100%;
    color : ${(props) => props.theme.secondaryFontColor};
    cursor :pointer;
    font-family: ${(props) => (props.theme.fontDefault)};
 }
 >div{
  width : 100%;
  svg{
    width :17px;
    height :15px;
  }
 }
`;