import React from "react";
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";
import ColumnChart from "src/charts/ColumnChart";
import VirtualizedSelect from 'react-virtualized-select';
import { Grid } from "@mui/material";
import subSystemMenuCrtl from "../dashboard.controllers"

const MyEndpoint = () => {
    const { wassData,
        yearsForWaas,
      } = subSystemMenuCrtl();

    return (
        <ChartContainer>
            <TitleContainer paddingtop="20px">درخواست های روزانه waas</TitleContainer>
            <Grid container>
            </Grid>
            <ColumnChart series={wassData} years={yearsForWaas} height="400" />
        </ChartContainer>
    )
}
export default MyEndpoint