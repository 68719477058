import Row from "src/kit/Row";
import { TableActions } from "./styles";
import { Grid, Box } from "@mui/material";
import WalletIcon from "src/assets/icons/WalletIcon";
import AddressCardIcon from "src/assets/icons/AddressCardIcon";

const WalletTableConfig = () => {
  return {
    showHeader: true,

    row: [
      {
        headerTitle: "ردیف",
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle: "نام کیف پول",
        displayTitle: "label",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.label !== "" ? props.row.label : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "بلاکچین",
        displayTitle: "blockchainName",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.blockchainName !== "" ? props.row.blockchainName : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "شبکه",
        displayTitle: "networkName",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.networkName !== "" ? props.row.networkName : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "تاریخ",
        displayTitle: "createdDate",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.createdDate !== "" ? props.row.createdDate : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "تعداد آدرس",
        displayTitle: "addressCount",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.addressCount !== "" ? props.row.addressCount : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "جزئیات",
        displayTitle: "id",
        size: 4,
        responsiveShow: false,
        element: (props) => {
          return (
            <TableActions>
              <Grid container>
                <Grid item p={1} display="flex" >
                  <Box type="detail" className="table_btn tooltip"
                   >
                    <Box className="tooltiptext">آدرس‌ها</Box>
                    <AddressCardIcon /></Box>
                </Grid>
                <Grid item p={1} display="flex" >
                  <Box type="detail" className="table_btn  tooltip"
                    >
                    <Box className="tooltiptext">تراکنش‌ها</Box>
                    <WalletIcon /></Box>
                </Grid>
              </Grid>
            </TableActions>
          );
        },
      },
    ],
  };
};
export default WalletTableConfig;