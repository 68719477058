import styled, { css } from "styled-components";
import Row from "src/kit/Row";
import Column from "src/kit/Column";


export const FirstLoginWrapper = styled(Row)`
    height :100vh;
    width: 100%;
    background-color: ${(props) => props.theme.primaryDark};
   
`;

export const FirstLoginContainer = styled(Column)`
    height :50vh;
    width: 500px;
    background-color: ${(props) => props.theme.white};
    justify-content : flex-start;
    #api_key_btn button{
    background-color: ${(props) => props.theme.primaryDark};

    }
`;
export const TitleConatiner = styled(Row)`
   border-bottom : 1px solid #ccc;
   width: 100%;
   padding :15px ;
   box-sizing : border-box;
   justify-content : flex-start;
   font-size: ${(props) => props.theme.fontSizeLg};


`;
