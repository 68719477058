import React from "react";
import paymentCrtl from "../payment.controller"
import Button from "src/kit/Button";
import { CardContainer, TableWrapper } from "src/shared/generalStyles";
import { PaymentContainer ,ActionsContainer} from '../styles'
import {
    Grid, Box,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    Table
} from "@mui/material";
import { NumberSeprator } from "src/utils";

const PrePayment = () => {
    const { handlePayment ,sliderData ,value ,cuAndDuration ,handlePrevStep ,unit} = paymentCrtl();

    return (

        <CardContainer>
            <PaymentContainer>
                <Grid container display="flex" justifyContent="start">
                    <Box className="text_bold">پیش پرداخت</Box>
                </Grid>


                <TableWrapper>
                    <TableContainer >
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">مبلغ</TableCell>
                                    <TableCell align="center">واحد محاسبه</TableCell>
                                    <TableCell align="center">تعداد روز</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow >
                                    <TableCell align="center">{NumberSeprator(unit)} ریال</TableCell>
                                    <TableCell align="center">{NumberSeprator(cuAndDuration?.computationUnits)}</TableCell>
                                    <TableCell align="center"> {NumberSeprator(cuAndDuration?.duration)}</TableCell>

                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableWrapper>


                <Grid container display="flex" justifyContent="end" id="payment-btn-container">
               
                    <Grid item xs={3} p={1} md={3} >
                        <Button
                            text="بازگشت"
                            onConfirm={() => handlePrevStep()}
                        />
                    </Grid>
                    <Grid item xs={3} p={1} md={3} >
                        <Button
                            text="پرداخت"
                            onConfirm={() => handlePayment()}
                        />
                    </Grid>

                </Grid>

            </PaymentContainer>

        </CardContainer>

    )
}

export default PrePayment