import React from "react";
import { PieChartContainer, } from "../styles"
import { Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";

const Chart = () => {
    const DashboardState = useSelector((state) => state.Dashboard);
    const theme = useSelector((state) => state.App.theme);

    const { statisticsCu } = DashboardState;
    return (
        <ChartContainer height="447px">
            <Grid container  display="flex" justifyContent="center" pb={5}>
                <TitleContainer paddingtop="20px">وضعیت اشتراک</TitleContainer>
            </Grid>
            <Grid container display="flex" justifyContent="center" alignItems="center" py={5}>
                <Grid item md={6} display="flex" justifyContent="center" alignItems="center">
                    <PieChartContainer>
                        <Box className="box">
                            <Box color={theme.primary} className="box-label"> واحد محاسبه </Box><Box className="orange-text">{statisticsCu?.usedCu}</Box>
                        </Box>
                        <Box className="box">
                            <Box color="#ccc" className="box-label"> واحد محاسبه </Box><Box className="gray-text"> {statisticsCu?.totalCu}</Box>
                        </Box>
                    </PieChartContainer>
                </Grid>
                <Grid item md={6} display="flex" justifyContent="center" alignItems="center">
                    <PieChartContainer>
                        <Box className="box">
                            <Box color={theme.primary} className="box-label">روز</Box> <Box className="orange-text"> {statisticsCu?.passedDays}</Box>
                        </Box>
                        <Box className="box">
                            <Box color="#ccc" className="box-label">روز</Box> <Box className="gray-text">{statisticsCu?.totalDays}</Box>
                        </Box>
                    </PieChartContainer>
                </Grid>
            </Grid>
        </ChartContainer>
    )
}
export default Chart;