
import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
NoDataToDisplay(Highcharts);

const DonutChart = ({ title, series,height,years }) => {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'pie',
            height,
            style :{
                fontFamily : 'inherit'
            },
            zoomType: 'xy',
        },
        lang: {
            noData: 'داده ای وجود ندارد',
          },
        title: {
            text: title
        },
        colors: [
            '#FF9800',
            '#0F183F',
        ],
        subtitle: {
            text: ''
        },
    
        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                valueSuffix: '%'
            }
        },
    
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.y}'
                }
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            borderColor: '#2c3e50',
            formatter: function () {  
                
                return `<div style="text-align: right"><span>${this.point.name} %${this.point.percentage.toFixed(2)}</span><br/>
           </div>`;
            }
        },
        series: series,
    });
       
    useEffect(() => {
       
        setChartOptions(prevState => ({
            ...prevState,
            series: series
        }))
    }, [series])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions} />
    )
}

export default DonutChart;