import React from "react";
import { ChartContainer, TitleContainer } from "src/shared/generalStyles";
import ColumnChart from "src/charts/ColumnChart";
import VirtualizedSelect from 'react-virtualized-select';
import { Grid } from "@mui/material";
import subSystemMenuCrtl from "../dashboard.controllers"

const MyEndpoint = () => {
  const { LineData ,selectedEndPoint ,endpointsListOptions ,years ,setselectedEndPoint ,sendStatisticsEndpointHandler} = subSystemMenuCrtl();

    return (
        <ChartContainer>
            <TitleContainer paddingtop="20px">نقطه اتصال من</TitleContainer>
            <Grid container>
                <Grid item xs={3} p={2}>
                    <VirtualizedSelect
                        value={selectedEndPoint}
                        noResultsText="موردی وجود ندارد"
                        placeholder="نقطه اتصال من"
                        onChange={(selected) => {
                            setselectedEndPoint(selected);
                            selected !== null && sendStatisticsEndpointHandler(selected);
                        }}
                        options={endpointsListOptions}
                    />
                </Grid>
            </Grid>
            <ColumnChart series={LineData} years={years} height="400" />
        </ChartContainer>
    )
}
export default MyEndpoint