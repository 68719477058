import styled from "styled-components";
import Column from "src/kit/Column";
import Row from "src/kit/Row";

export const PaymentContainer = styled(Column)`
width: 100%;
padding: 20px;
box-sizing: border-box;
background-color: #fff;
height: calc(100% - 50px);
box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.10);
margin-bottom :20px;
justify-content: space-between;

.MuiSlider-root{
    height :16px;
    color : #E8EDF8;
}
.MuiSlider-rail{
    opacity: 1;
}
.MuiSlider-track{
    color : ${props => props.theme.primaryDark};
}
.MuiSlider-thumb{
    color : ${props => props.theme.primaryDark};
    border :2px solid White;
    height:30px;
    width:30px;
}
#payment-btn-container{
    border-top : 1px solid #ccc;
    padding :40px 0 20px 0;
}
.MuiSlider-valueLabelOpen {
    background-color: ${props => props.theme.primaryDark};
    font-size : ${(props) => (props.theme.fontSizeXl)};
    padding:10px 20px;
}
.MuiSlider-mark{
    height:5px;
    width:5px;
    background-color: ${props => props.theme.primary};
}


.box-container{
   border :1px solid #D9D9D9;
   padding: 10px;
   border-radius:5px;
   color : #ABAFB3;
   box-sizing: border-box;
   display :flex;
   justify-content : center;
   display: flex;
    flex-direction: column;
    min-height:100px;
 }
 .text_bold{
  color : ${props => props.theme.primaryDark};
  font-family: ${(props) => (props.theme.fontBold)};
  font-size : ${(props) => (props.theme.fontSizeXXl)};
 }
 #price-box{
    padding: 15px 30px;
    border-radius: 10px;
    width: 160px;
    text-align: center;
    background-color :#E8EDF8;
 }

`;
/////////////////////////////////////////payment

export const PurchaseWrapper = styled(Column)`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100% - 50px);
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.10);
    margin-bottom :20px;
    #purchase-container{

        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
   
`;
export const PurchaseImageContainer = styled(Row)`
    height : 280px;
    width : 50%;
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: center;
    background-size: contain;
    border-radius : 5px;
  
`;
export const ActionsContainer = styled(Row)`
    direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};
    width:100%;
    justify-content : ${(props) => props.justify ? props.justify : "center"};
    .btn{
        margin-top :10px;
        background-color: ${(props) => props.theme.primary};
        border-radius: ${(props) => props.theme.borderRadius};
        border: 1px solid ${(props) => props.theme.primary};
        width: 160px;
        padding: 10px;
        color : ${(props) => props.theme.white};
        cursor :pointer;
        font-family: ${(props) => (props.theme.fontDefault)};
        margin: 5px;
     }
     .btn:hover {
        color : ${(props) => props.theme.white};
        background-color: ${(props) => props.theme.primary};
      }
     #return{
        background-color: ${(props) => props.theme.white};
        color : ${(props) => props.theme.primary};
     }
 
  
`;
export const TextContainer = styled(Row)`
  color : ${(props) => props.color};
  padding:30px 0;
  

`;
