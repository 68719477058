import { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { ImageWrapper ,ImageContainer } from "./styles";
import Avatar from "src/assets/images/avatar.png";

const FileInput = ({userInfo ,selectedImage ,setSelectedImage ,setSelectedFile }) => {
    const [imageUrl, setImageUrl] = useState("data:image/jpeg;base64," + userInfo?.logo);

    useEffect(() => {
         setImageUrl(userInfo?.logo && "data:image/jpeg;base64," + userInfo?.logo);
    }, [userInfo]);
    useEffect(() => {
        if (selectedImage) {
            setImageUrl(selectedImage);
        }
    }, [selectedImage]);
;

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange = e => {
    console.log(e.target.files[0]);

    let file = e.target.files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        setSelectedImage(result);
      })
      .catch(err => {
        console.log(err);
      });
  };
     
    return (
        <ImageWrapper>
        <Grid width="100%" display="flex" justifyContent="center">
            <input
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: "none" }}
                onChange={(e) => {
                    setSelectedFile(e.target.files[0])
                    handleFileInputChange(e);
                }} />
            {(<Box mt={2} textAlign="center">
                <div></div>
                <ImageContainer src={imageUrl} height="130px">
                    <label htmlFor="select-image" id="icon_container">
                        <Box
                            className="clickable"
                            variant="contained"
                            component="span">
                             بارگذاری تصویر
                        </Box>
                    </label>
                </ImageContainer>
            </Box>)}
        </Grid>
        </ImageWrapper>);
}; export default FileInput;