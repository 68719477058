import React from "react";
import { Formik } from "formik";
import { FormInput } from "src/kit/Form";
import FormController from "src/controllers/FormController";
import { useDispatch, useSelector } from "react-redux";
import WalletActions from "src/redux/wallet/Actions";
import { Grid } from "@mui/material";

const FilterMenu = (props) => {
    const dispatch = useDispatch();
    const { filterState, setFilterState } = props;
    const { title } = filterState;
    const EndpointState = useSelector((state) => state.Endpoint);
    const { takeEndpoint } = EndpointState;

    const handleSearchContent = (e) => {
        setFilterState({
            ...filterState,
            title: e.target.value
        });
        dispatch(
            WalletActions.getWallet({
                title: e.target.value,
                offset: 0,
                limit: takeEndpoint,
            })
        );
    };
    return (
        <Formik
            initialValues={
                {
                    title,
                }
            }
            isInitialValid={false}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                handleSearchContent(values, action);
            }}

        >
            {(formik) => (

                <form className="py-5" onSubmit={formik.handleSubmit}>
                    <Grid container>
                        <Grid item md={3}>
                            <FormInput
                                value={formik.values.title}
                                name="title"
                                id="title"
                                placeholder="جستجو"
                                onChange={(e) => { FormController.onChange(e, formik, null); handleSearchContent(e, formik); }}
                                className={
                                    "custom_input" + (formik.errors.title && formik.touched.title ? " warning-bottom-border" : "")
                                }
                            />
                            {formik.errors.title && formik.touched.title ? (
                                <div className="text-danger text-small text-alert">{formik.errors.title}</div>
                            ) : null}

                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    )
}

export default FilterMenu;
