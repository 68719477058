import {
  all,
  takeEvery,
  put,
  fork,
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import { loginCode } from "src/utils/loginCode";


export function* GetProfileInfo() {
  yield takeEvery(types.GET_PROFILE_INFO, function* ({ data }) {
    let response = [];
    try {
      response = yield Api.Get(Endpoints.GetProfileInfo, true);
      if (response) {
        yield put({
          type: types.GET_PROFILE_INFO_SUCCESS,
          data: { data: response },
        });
      } else
        yield put({
          type: types.GET_PROFILE_INFO_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_PROFILE_INFO_FAIL,
      });
    }
  });
}


export function* UpdateProfileInfo() {
  yield takeEvery(types.UPDATE_PROFILE_INFO, function* ({ data }) {

    // const Data = new FormData();
    // Data.append("orgName", data.values.name);
    // Data.append("logo", data.logo);

    let Data = {
      orgName : data.values.name,
      logo : data.logo
    }

    try {
      const response = yield Api.Post(Endpoints.UpdateProfileInfo, Data, true);
      if (response) {
        yield put({
          type: types.UPDATE_PROFILE_INFO_SUCCESS,
          data: response
        });
        yield put({
          type: types.GET_PROFILE_INFO,
          data: response,
        });
        // data?.callBack();
        toast.success("با موفقیت انجام شد", { position: toast.POSITION.BOTTOM_RIGHT });

      } else
        yield put({
          type: types.UPDATE_PROFILE_INFO_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.UPDATE_PROFILE_INFO_FAIL,
      });
    }
  });
}

export function* ChangePassword() {
  yield takeEvery(types.CHANGE_PASSWORD, function* ({ data }) {

    let body = {
      currentPassword: data.values.currentPassword,
      newPassword: data.values.newPassword,
      confirmPassword: data.values.confirmPassword
    }
    try {
      const response = yield Api.Post(Endpoints.ChangePassword, body, true);
      if (response) {
        yield put({
          type: types.CHANGE_PASSWORD_SUCCESS,
          data: response
        });
        // data?.callBack();
        toast.success("با موفقیت انجام شد", { position: toast.POSITION.BOTTOM_RIGHT });

      } else
        yield put({
          type: types.CHANGE_PASSWORD_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.CHANGE_PASSWORD_FAIL,
      });
    }
  });
}

export function* GenerateApiKey() {
  yield takeEvery(types.GENERATE_API_KEY, function* ({ data }) {
    let body = {

    }

    try {
      const response = yield Api.Post(Endpoints.GenerateApiKey, body, true);
      data.action.setSubmitting(false);
      // data.action.resetForm();
      if (response) {
        yield put({
          type: types.GENERATE_API_KEY_SUCCESS,
          data: response
        });
        data?.callBack();
        toast.success("با موفقیت انجام شد", { position: toast.POSITION.BOTTOM_RIGHT });

      } else
        yield put({
          type: types.GENERATE_API_KEY_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      console.log(e);
      toast.error("عملیات ناموفق", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GENERATE_API_KEY_FAIL,
      });
    }
  });
}

export function* GetApiKeyList() {
  yield takeEvery(types.API_KEY_lIST, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetApiKeyList + `${data.id}/detail`, true);
      if (response) {
        yield put({
          type: types.API_KEY_lIST_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.API_KEY_lIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.API_KEY_lIST_FAIL,
      });
    }
  });
}
export default function* UserSagas() {
  yield all(
    [
      fork(UpdateProfileInfo),
      fork(GetProfileInfo),
      fork(ChangePassword),
      fork(GenerateApiKey),
      fork(GetApiKeyList)
    ]);
}