import * as Yup from "yup";
const requireMessage = "این فیلد الزامی است.";

export const string = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.string()
            .label(label)
            .typeError(requireMessage)
            .required(requireMessage);
    } else {
        return Yup.string().label(label).nullable(true);
    }
};


export const number = (label, isRequire = false) => {
    if (isRequire) {
      return Yup.number()
        .label(label)
        .typeError(requireMessage)
        .required(requireMessage);
    } else {
      return Yup.string().label(label).nullable(true);
    }
  };

  export const boolean = (label, isRequire = false) => {
    if (isRequire) {
        return Yup.boolean()
            .label(label)
            .typeError(requireMessage)
            .required(requireMessage);
    } else {
        return Yup.boolean().label(label).nullable(true);
    }
};