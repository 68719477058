
import Row from "src/kit/Row";
import moment from "moment-jalaali";


const PaymentHistoryTableConfig = () => {
  return {
    showHeader: true,

    row: [
      {
        headerTitle: "ردیف",
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle: "قیمت",
        displayTitle: "price",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.price !== "" ? props.row.price : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "واحد محاسبه",
        displayTitle: "totalComputationUnit",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.totalComputationUnit !== "" ? props.row.totalComputationUnit : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "تاریخ شروع",
        displayTitle: "startDate",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              { props.row.startDate !== null  ? moment(props.row.startDate).format("jYYYY-jMM-jDD") : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "تاریخ پایان",
        displayTitle: "finishedDate",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              { props.row.finishedDate !== null ? moment(props.row.finishedDate).format("jYYYY-jMM-jDD") : "-"}
            </Row>
          );
        },
      },
    ],
  };
};
export default PaymentHistoryTableConfig;