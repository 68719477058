
export const modulesList = [
  {
    id: "0",
    title: "mainSystem",
    displayTitle: "دانادل",
    visible: true,
    basePath: "/",
    hasSubSystem: true,
    subSystem: [
      {
        id: "01",
        title: "داشبورد",
        displayTitle: "داشبورد",
        path: "/dashboard",
        visible: true,
        hasMenus: false,
        hasDetail:false,
        menus: [],
      },
      {
        id: "02",
        title: "نقطه اتصال",
        displayTitle: "نقطه اتصال",
        path: "/endpoint",
        visible: true,
        hasMenus: false,
        hasDetail:true,
        detailUrl:"/endpoint/detail",
        menus: [],
      },
      {
        id: "03",
        title: "سرویس کیف پول",
        displayTitle: "سرویس کیف پول",
        path: "/wallet",
        visible: true,
        hasMenus: false,
        hasDetail:false,
        menus: [],
      },
      {
        id: "04",
        title: "پرداخت",
        displayTitle: "پرداخت",
        path: "/payment",
        visible: true,
        hasMenus: true,
        menus: [
          {
            id: "041",
            title: "payment",
            displayTitle: "خرید",
            path: "/payment/purchase",
            visible: true,
          },
          {
            id: "042",
            title: "paymentHistory",
            displayTitle: "تاریخچه پرداخت‌ها",
            path: "/payment/paymentHistory",
            visible: true,
          }]
      },
    ],
  },
];
