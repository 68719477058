import React from "react";
import {
  SubSystemWrapper,
  SubSystemContainer,
  SubSystemItemContainer,
  SubSystemItem,
  SubSystemMenusContainer,
  SubSystemMenusItem,
  IconContainer,
  AvatarLogoContainer 
} from "./style";
import RoleIcon from "src/assets/icons/EndpointIcon";
import UserIcon from "src/assets/icons/Dashboard";
import WalletIcon from "src/assets/icons/WalletIcon";
import PaymentIcon from "src/assets/icons/PaymentIcon";
import ChevronIcon from "src/assets/icons/ChevronIcon"
import { useSelector } from "react-redux";
import AvatarIcon from "src/assets/icons/AvatarIcon"
import { Box } from "@mui/material";
import LogoIcon from "src/assets/icons/LogoIcon"
import storage from "src/storage";

const SubSystemMenu = ({
  activeSystem,
  activeSubSystem,
  selectSubSystemHandler,
  selected,
  toggleHandler,
  activeSubSystemMenu,
}) => {
  const theme = useSelector((state) => state.App.theme);
  const LoginState = useSelector((state) => state.User);
  const { userInfo ,logo } = LoginState;
  const logo1 = encodeURIComponent(logo)

  const SubSystemIcon = (id) => {
    const isActive = id === activeSubSystem.id;
    const stroke = isActive === true ? theme.secondary : theme.secondary;
    switch (id) {
      ////////////////////seconder
      case "02":
        return <RoleIcon stroke={stroke} />;
      case "01":
        return <UserIcon stroke={stroke} />;
      case "03":
        return <WalletIcon stroke={stroke} />;
        case "04":
          return <PaymentIcon stroke={stroke} />;
      default:
        break;
    }
  };
  return (
    <SubSystemWrapper>
      <AvatarLogoContainer>
        {
          (userInfo.logo === "" || userInfo.logo === null) ?
            <AvatarIcon /> :
          <img src={`data:image/jpeg;base64,${userInfo?.logo}`} id="company-logo"/>
        }
      </AvatarLogoContainer>
      <SubSystemContainer>
        {activeSystem?.subSystem.map((each) => {
          return (
            each.visible === true && (

              <SubSystemItemContainer title={each.title} key={each.id}>
                <SubSystemItem
                  to={each.menus.length > 0 ? "#" : each.path}
                  active={activeSubSystem.id === each.id && selected === each.id}
                  onClick={() => {
                    toggleHandler(each.id);
                    selectSubSystemHandler(each);
                  }}
                >
                  {SubSystemIcon(each.id)}
                  <span>{each.displayTitle}</span>
                  <IconContainer className="o__subSystem__chevronIcon">
                    {each.hasMenus && <ChevronIcon />}
                  </IconContainer>
                </SubSystemItem>
                {activeSubSystem.id === each.id && selected === activeSubSystem.id && (
                  <SubSystemMenusContainer>
                    {each?.menus.map((menu) => {

                      return (
                        menu.visible === true && selected === activeSubSystem.id && (
                          <SubSystemMenusItem
                            active={activeSubSystemMenu.id === menu.id && selected === activeSubSystem.id}
                            to={menu.path}
                            key={menu.id}
                            onClick={() => {
                              menu.displayTitle === "خرید" && storage.clearPaymentInfo()
                            }}
                          >
                            {menu.displayTitle}
                          </SubSystemMenusItem>
                        )
                      );
                    })}
                  </SubSystemMenusContainer>
                )}
              </SubSystemItemContainer>
            )
          );
        })}

      </SubSystemContainer>
      <AvatarLogoContainer>
        <LogoIcon />
      </AvatarLogoContainer>
    </SubSystemWrapper>
  );
};

export default SubSystemMenu;
