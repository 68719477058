import React, { useRef, useState } from "react";
import { HeaderWrapper, LogoContainer, TitleContainer } from "./styles";
import AvatarIcon from "src/assets/icons/AvatarIcon";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import UserActions from "src/redux/User/Actions"
import ProfileModal from "src/views/modal/profileModal";
import ChangePasswordModal from "src/views/modal/changePasswordModal";

import useOutsideClick from "../../utils/methods/OutSideClickHandler";
import UserMenu from "./UserMenu";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation,  } from "react-router-dom";
import {Link} from "@mui/material"


const Header = (props) => {
    const { activeSubSystem, activeSubSystemMenu } = props;
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.App.theme);
    const showUserProfileModal = useSelector((state) => state.User.showUserProfileModal);
    const showChangePasswordModal = useSelector((state) => state.User.showChangePasswordModal);
    let location = useLocation();
    let parts = location.pathname.split("/").slice(1);

    const subMenuRef = useRef();
    const [showSubMenu, setShowSubMenu] = useState(false);
    const userMenuHandler = () => {
        setShowSubMenu(!showSubMenu);
    };
    useOutsideClick(subMenuRef, () => {
        setShowSubMenu(false);
    });
    const ChangeUserAccountModalHandler = (show) => {
        dispatch(UserActions.showUserProfileModal({ visible: show }));
    }
    const ChangePasswordModalHandler = (show) => {
        dispatch(UserActions.showChangePasswordModal({ visible: show }));
    }
    return (
        <>
            <HeaderWrapper>
                <div ref={subMenuRef}>
                    <LogoContainer>
                        <Box onClick={userMenuHandler} className="clickable">
                            <AvatarIcon pathFill={theme.textSecondary} />
                        </Box>
                        <UserMenu setShowSubMenu={setShowSubMenu} visible={showSubMenu} />
                    </LogoContainer>
                </div>
            </HeaderWrapper>
            <TitleContainer>
                {activeSubSystem.hasMenus !== true ?
                    <Breadcrumbs aria-label="breadcrumb">
                       
                                <Link underline="hover" color="inherit" href={activeSubSystem.path}>
                                    {activeSubSystem.displayTitle}
                                </Link>
                                {
                                    parts[1] && activeSubSystem.hasDetail &&
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                    >
                                        جزئیات
                                    </Link>
                                }
                    </Breadcrumbs>
                    :
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" >
                            {activeSubSystem.displayTitle}
                        </Link>
                        {activeSubSystemMenu !== null &&
                            <Link
                                underline="hover"
                                color="inherit"
                                href={activeSubSystemMenu.path}
                            >
                                {activeSubSystemMenu.displayTitle}
                            </Link>}
                    </Breadcrumbs>
                }
            </TitleContainer>
            <ProfileModal
                open={showUserProfileModal}
                title="حساب کاربری"
                onClose={ChangeUserAccountModalHandler}
            />
            <ChangePasswordModal
                open={showChangePasswordModal}
                title="تغییر رمز عبور"
                onClose={ChangePasswordModalHandler}
            />
        </>

    )
}
export default Header