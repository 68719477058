 export const loginCode =[
    {code : "001" , title:"ایمیل و رمز عبور وارد شده تطابق ندارند."},
    {code : "002" , title:"تعداد تلاش برای ورود بیش از حد مجاز است، لطفا بعد از مدتی دوباره تلاش کنید."},
    {code : "003" , title:"برای ثبت ملزم به پذیرش توافق نامه هستید."},
    {code : "004" , title:"ثبت نام شما با موفقیت انجام شد. برای تکمیل ثبت نام لینک تایید به ایمیل ارسال شده است."},
    {code : "005" , title:"ایمیل وارد شده قبلا ثبت شده است."},
    {code : "006" , title:"تکرار رمز عبور صحیح نمی‌باشد."},
    {code : "007" , title:"پسورد وارد شده امنیت کافی را ندارد. باید حداقل هشت حرف و شامل یک رقم، حرف کوچک، حرف بزرگ و علامت داشته باشد."},
    {code : "008" , title:"در صورت درست بودن ایمیل،‌ لینک بازیابی رمز عبور برای شما ارسال می‌گردد."},
    {code : "009" , title:"تکرار رمز عبور صحیح نمی‌باشد."},
    {code : "010" , title:"پسورد وارد شده امنیت کافی را ندارد. باید حداقل هشت رقم و شامل یک رقم، حرف کوچک، حرف بزرگ و علامت داشته باشد."},
    {code : "011" , title:"پسورد با موفقیت تغییر کرد."},
    {code : "012" , title:""},
    {code : "013" , title:"پروفایل با موفقیت ذخیره شد."},
    {code : "014" , title:"شما نمیتوانید اطلاعات این endpoint را مشاهده کنید"},
    {code : "015" , title:"نام ولت تکراری است"},
    {code : "016" , title:"نام کابری یا ایمیل مورد نظر درست نمیباشد"},
    {code : "017" , title:"پسورد وارد شده درست نمیباشد."},
    {code : "018" , title:"بازه زمانی درست نمیباشد"},
    {code : "019" , title:"حساب کاربری فعال نمیباشد"}

]